<template>
  <main id="content" role="main" class="">
    <div class="gradient-x-overlay-sm-indigo position-relative overflow-hidden" style="background-color: #F0F8FD;">
      <div class="container space-top-3 space-bottom-2">
        <div class="w-lg-60 text-center mx-lg-auto">
          <h1 class="mb-3">Contact Us</h1>
          <p class="lead">Get in touch and let us know how we can help.</p>
        </div>
      </div>

      <div class="container space-bottom-2">
        <div class="w-md-80 mx-auto">

          <div class="card" data-aos="fade-up" data-aos-delay="100">
            <div class="card-body">
              <form ref="benGeneralForm" />
            </div>
          </div>

          <div class="mt-11">
            <h3 class="mb-3">Contact Information</h3>
            <div class="row">
              <div class="col-sm-6 mb-3 mb-sm-5">
                <div class="card h-100">
                  <div class="card-body">
                    <figure class="max-w-6rem w-100 mb-3">
                      <img class="img-fluid" src="@/assets/svg/icons/icon-8.svg">
                    </figure>
                    <h4 class="mb-3">Hong Kong (Headquarter)</h4>
                    <h6>beNovelty Limited</h6>
                    <p>Unit 320, 3/F, Building 16W, Phase 3<br>
                      No. 16 Science Park West Avenue<br>
                      Hong Kong Science Park<br>
                      Shatin, N.T., Hong Kong</p>
                    <a href="https://goo.gl/maps/qRucKAtMNeCWpb7VA" target="_blank" class="font-weight-bold">Get directions <i class="far fa-arrow-right ml-1" /></a>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 mb-3 mb-sm-5">
                <div class="card h-100">
                  <div class="card-body">
                    <figure class="max-w-6rem w-100 mb-3">
                      <img class="img-fluid" src="@/assets/svg/icons/icon-8.svg">
                    </figure>
                    <h4 class="mb-3">London (Europe Branch)</h4>
                    <h6>beNovelty Technology UK Limited</h6>
                    <p>2nd Floor, 107 Charterhouse St<br>
                      Barbican, London<br>
                      EC1M 6HW<br>
                      United Kingdom</p>
                    <a href="https://goo.gl/maps/qffpHJ5W6ECxnzBA8" target="_blank" class="font-weight-bold">Get directions <i class="far fa-arrow-right ml-1" /></a>
                  </div>
                </div>
              </div>

              <!-- <div class="col-sm-4 mb-3 mb-sm-5">
                <div class="card h-100">
                  <div class="card-body">
                    <figure class="max-w-6rem w-100 mb-3">
                      <img class="img-fluid" src="@/assets/svg/icons/icon-16.svg">
                    </figure>
                    <h4>Office Number</h4>
                    Hong Kong: <a href="tel:+852 3598-3658" class="font-weight-bold">+852 3598-3658</a>
                  </div>
                </div>
              </div> -->

              <div class="col-sm-6 mb-3 mb-sm-5">
                <div class="card h-100">
                  <div class="card-body">
                    <figure class="max-w-6rem w-100 mb-3">
                      <img class="img-fluid" src="@/assets/svg/icons/icon-15.svg">
                    </figure>
                    <h4>Email</h4>
                    <a href="mailto: info@beNovelty.com" class="font-weight-bold">info@beNovelty.com</a>
                  </div>
                </div>
              </div>

              <div class="col-sm-6 mb-3 mb-sm-5">
                <div class="card h-100">
                  <div class="card-body">
                    <figure class="max-w-6rem w-100 mb-3">
                      <img class="img-fluid" src="@/assets/svg/icons/icon-4.svg">
                    </figure>
                    <h4>Social</h4>
                    <ul class="list-inline mb-0">
                      <li class="list-inline-item">
                        <a class="btn btn-sm btn-icon btn-soft-primary" href="https://www.facebook.com/beNovelty" target="_blank">
                          <i class="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li class="list-inline-item">
                        <a class="btn btn-sm btn-icon btn-soft-primary" href="https://www.linkedin.com/company/benovelty/" target="_blank">
                          <i class="fab fa-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <!-- SVG Background Shape -->
        <figure class="w-25 position-absolute bottom-0 left-0 z-index-n1 mb-n11 ml-n11">
          <img class="img-fluid" src="@/assets/svg/components/half-rhombus-2.svg" alt="Image Description">
        </figure>
      <!-- End SVG Background Shape -->
      </div>

    </div>
  </main>

</template>

<script>
export default {
  name: 'Contact',
  components: {
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
    this.loadScriptBenGeneralForm('https://js.hsforms.net/forms/v2.js', () => {
      const secondScript = document.createElement('script')
      secondScript.innerHTML = `
        hbspt.forms.create({
          portalId: "7091000",
          formId: "13239e83-63b2-4469-8e47-aaf91bdc82d2"
        });
      `
      this.$refs.benGeneralForm.appendChild(secondScript)
    })
  },
  created() {
    this.partner_list = this.$store.getters.partners.partner_list
  },
  methods: {
    loadScriptBenGeneralForm(url, callback) {
      var script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = url
      script.onreadystatechange = callback
      script.onload = callback
      this.$refs.benGeneralForm.appendChild(script)
    }
  },
  metaInfo() {
    return {
      title: 'beNovelty',
      titleTemplate: 'Contact | %s',
      link: [
        { rel: 'canonical', href: 'https://www.benovelty.com/contact' }
      ],
      meta: [
        { name: 'description', content: 'Discover beNovelty\'s state-of-the-art API-First and intelligent Agentic AI Solutions, designed to elevate your enterprise\'s digital transformation.' },
        { property: 'og:site_name', content: 'beNovelty' },
        { property: 'og:title', content: 'Contact | beNovelty' },
        { property: 'og:description', content: 'An award-winning one-stop API platform services connecting digital businesses to embrace Open API Economy.' },
        { property: 'og:image', content: 'https://www.benovelty.com/assets/img/thumbnails/common.jpg' },
        { property: 'og:url', content: 'https://www.benovelty.com/contact' },
        { name: 'twitter:card', content: 'summary_large_image' }
      ]
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import url("../../assets/vendor/slick-carousel/slick/slick.css");
</style>
